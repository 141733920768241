<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialogSkill" persistent max-width="800px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_skill === 'add'" class="headline"
                >Thêm kỹ năng</span
              >
              <span v-if="type_form_skill === 'update'" class="headline"
                >Sửa kỹ năng</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSkill = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3" class="text-right p-0 m-0"><label class="text-h6">Chọn kỹ năng</label></v-col>
                    <v-col cols="7" class="p-0 m-0 ml-4">
                      <v-select
                        dense
                        required
                        outlined
                        v-if="type_form_skill === 'add'"
                        :items="data_option_skills"
                        v-model="inputTitleSkill"
                      ></v-select>
                      <v-text-field
                        v-if="type_form_skill === 'update'"
                        dense
                        outlined
                        v-model="inputTitleSkill"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <template v-if="inputTitleSkill === 'Speaking'">
                    <v-row class="mt-0">
                      <v-col cols="3" class="p-0 m-0">
                        <v-checkbox
                            v-model="has_vsr_config"
                            :readonly="!(type_form_skill === 'add')"
                            dense
                            class="mt-0 float-right">
                          <template v-slot:label>
                            <label class="text-h6 cursor-pointer">VSR</label>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="7" class="p-0 m-0 ml-4">
<!--                        <v-select-->
<!--                            :readonly="!(type_form_skill === 'add')"-->
<!--                            v-if="has_vsr_config"-->
<!--                            v-model="vsr_config_input"-->
<!--                            dense outlined-->
<!--                            :items="vsr_config_items"-->
<!--                        ></v-select>-->
                      </v-col>
                    </v-row>
                  </template>

                  <template v-if="inputTitleSkill === 'Writing'">
                    <v-row class="mt-0">
                      <v-col cols="3" class="p-0 m-0">
                        <v-checkbox
                            v-model="has_vwr_config"
                            :readonly="!(type_form_skill === 'add')"
                            dense
                            class="mt-0 float-right">
                          <template v-slot:label>
                            <label class="text-h6 cursor-pointer">VWR</label>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="7" class="p-0 m-0 ml-4">
<!--                        <v-select-->
<!--                            :readonly="!(type_form_skill === 'add')"-->
<!--                            v-if="has_vwr_config"-->
<!--                            v-model="vwr_config_input"-->
<!--                            dense outlined-->
<!--                            :items="vwr_config_items"-->
<!--                        ></v-select>-->
                      </v-col>
                    </v-row>
                  </template>

                  <v-row class="mt-0 mb-3">
                    <v-col cols="3" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Thời gian(giờ/ phút)</label
                      ></v-col
                    >
                    <v-col cols="7" class="p-0 m-0 ml-4">
                      <date-picker
                        v-model="inputDurationSkill"
                        type="time"
                        format="HH:mm"
                        value-type="format"
                        placeholder="Giờ:Phút"
                      ></date-picker>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="3" class="text-right p-0 m-0"
                      ><label class="text-h6">Marking point</label></v-col
                    >
                    <v-col cols="7" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        outlined
                        v-model="marking_point_input"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogSkill = false"
              >Đóng</v-btn
            >
            <v-btn
              color="blue"
              text
              bold
              v-if="type_form_skill === 'add'"
              @click="btnAddSkill"
              >Thêm
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              v-if="type_form_skill === 'update'"
              @click="btnEditSkill"
              >Cập nhật
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApiService from "@/service/api.service";
import productCategorySupport from "./Support/Test/productCategorySupport";

export default {
  name: "SkillModal",
  mixins: [
      productCategorySupport
  ],
  data() {
    return {
      optionSkill: [],
      id_skill_test_mapping: null,
      marking_point_input: null,

      has_vsr_config: false,
      vsr_config_items: [
          'VSR TOEIC',
          'VSR IELTS',
      ],
      vsr_config_input: 'VSR TOEIC',

      has_vwr_config: false,
      vwr_config_items: [
          'VWR TOEIC',
          'VWR IELTS',
      ],
      vwr_config_input: 'VWR TOEIC',
    };
  },
  computed: {
    ...mapState({
      test_id: (state) => state.informationTestBuilderStore.test_id,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      time_restriction_test: (state) =>
        state.informationTestBuilderStore.time_restriction_test,
      data_option_skills: (state) =>
        state.informationTestBuilderStore.data_option_skills,
      type_form_skill: (state) => state.contentTestBuilderStore.type_form_skill,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    tabSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.tabSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTabSkill", value);
      },
    },
    dialogSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDiaLogSkill", value);
      },
    },
    inputTitleSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.title_skill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTitleSkill", value);
      },
    },
    inputDurationSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.duration_skill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDurationSkill", value);
      },
    },
    hostCurrent() {
      return window.location.host;
    },
  },
  watch: {
    dialogSkill: function (val) {
      if (val === true) {
        this.setDataInput(this.type_form_skill);
      }
    },
  },
  mounted() {

  },
  methods: {
    setDataInput(type) {
      if (type === "add") {
        this.inputTitleSkill = null;
        this.inputDurationSkill = null;
        this.marking_point_input = null;

        this.has_vsr_config = false;
        this.vsr_config_input = null;

        this.has_vwr_config = false;
        this.vwr_config_input = null;
      } else {
        let data = this.skills[this.indexSkill];
        data = JSON.parse(JSON.stringify(data));
        this.id_skill_test_mapping = data.id_skill_test_mapping;
        this.inputTitleSkill = data.skill_type;
        this.inputDurationSkill = data.duration == null ? data.duration : this.$options.filters.convertSecondToHM(data.duration);
        this.marking_point_input = data.marking_point;
        this.has_vsr_config = data.vsr === 1;
        this.has_vwr_config = data.vwr === 1;
      }
    },
    validateForm(type_form) {
      let flat = true;
      if (this.inputTitleSkill == null) {
        this.messError("Hãy chọn 1 kỹ năng !!")
        flat = false;
      }
      if (this.time_restriction_test === "Yes") {
        if (this.inputDurationSkill === "" || this.inputDurationSkill == null) {
          this.$toasted.error("Bạn chưa nhập thời gian cho kỹ năng !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (type_form === "add") {
        if (this.inputTitleSkill != null) {
          let type_skills = this.skills.map((skill) => skill["skill_type"]);
          if (type_skills.includes(this.inputTitleSkill)) {
            this.$toasted.error("Kĩ năng này đã được thêm trong bài less/test này !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      // if (this.has_vwr_config) {
      //   if (this.vwr_config_input === 'VWR TOEIC' && this.product_category_id !== this.product_category_const.TOEIC) {
      //     this.messError('Không thể chọn cấu hình VWR TOEIC với kỳ thi '+ this.getObjPCategoryById(this.product_category_id)?.slug.toUpperCase());
      //     flat = false;
      //   }
      //   if (this.vwr_config_input === 'VWR IELTS' && this.product_category_id !== this.product_category_const.IELTS) {
      //     this.messError('Không thể chọn cấu hình VWR IELTS với kỳ thi '+ this.getObjPCategoryById(this.product_category_id)?.slug.toUpperCase());
      //     flat = false;
      //   }
      // }
      // if (this.has_vsr_config) {
      //   if (this.vsr_config_input === 'VSR TOEIC' && this.product_category_id !== this.product_category_const.TOEIC) {
      //     this.messError('Không thể chọn cấu hình VSR TOEIC với kỳ thi '+ this.getObjPCategoryById(this.product_category_id)?.slug.toUpperCase());
      //     flat = false;
      //   }
      //   if (this.vsr_config_input === 'VSR IELTS' && this.product_category_id !== this.product_category_const.IELTS) {
      //     this.messError('Không thể chọn cấu hình VSR IELTS với kỳ thi '+ this.getObjPCategoryById(this.product_category_id)?.slug.toUpperCase());
      //     flat = false;
      //   }
      // }
      return flat;
    },
    async btnAddSkill() {
      let validate = this.validateForm("add");
      if (!validate) {
        return;
      }
      let vm = this;
      vm.is_call_api = true;
      let data = {
        id_test: this.test_id,
        skill_type: this.inputTitleSkill,
        duration: this.time_restriction_test === "Yes" ? this.$options.filters.convertHMToSecond(this.inputDurationSkill) : null,
        currentTabSection: 0,
        totalSection: 0,
        sections: [],
        vsr: this.has_vsr_config ? 1 : 0,
        vwr: this.has_vwr_config ? 1 : 0,
        product_category_id: this.product_category_id,
        offset: this.skills.length +1,
      };
      if (!(this.marking_point_input == null || this.marking_point_input === "")) data.marking_point = this.marking_point_input;

      await ApiService.post("prep-app/test/draft/skill", data)
        .then(function (res) {
          console.log(res)
          if (res.status === 200) {
            data.id = res.data.id_skill;
            data.id_skill_test_mapping = res.data.id_skill_test_mapping;
            if (data.vsr === 1) {
              data.sections = vm.convertDataSection(res.data.list_section_for_vsr_via_category);
            }
            if (data.vwr === 1) {
              data.sections = vm.convertDataSection(res.data.list_section_for_vwr_via_category);
            }
            vm.$store.commit("contentTestBuilderStore/addSkill", data);
            vm.tabSkill = vm.skills.length - 1;
            vm.inputTitleSkill = null;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          if (error) {
            console.log(error);
            vm.is_call_api = false;
            if ([422].includes(error.response.status) ) {
              vm.messError(error.response.data.error.message);
            } else {
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
            }
          }
        });
      vm.dialogSkill = false;
    },
    convertDataSection(sections) {
      for (let iS = 0; iS < sections.length; iS++) {
        sections[iS].section_title = sections[iS].title;
        sections[iS].section_description = sections[iS].content;
        sections[iS].element_data = null;
        sections[iS].element_type = null;
        sections[iS].element_id = null;
        sections[iS].element_timestamp = [];
        sections[iS].is_element_pinned = false;
        sections[iS].sub_element_data = null;
        sections[iS].sub_element_type = null;
        sections[iS].sub_element_id = null;
        delete sections[iS].title;
        delete sections[iS].content;
        sections[iS].exercises = [];
        sections[iS].total_question = 0;
        sections[iS].offset_question = 0;
        sections[iS].id_section_test_mapping = sections[iS].section_test_mapping_id;
      }
      return sections;
    },
    async btnEditSkill() {
      let validate = this.validateForm("update");
      if (!validate) {
        return;
      }
      let vm = this;
      vm.is_call_api = true;
      let data = {
        id_test: this.test_id,
        product_category_id: this.product_category_id,
        duration: this.time_restriction_test === "Yes" ? this.$options.filters.convertHMToSecond(this.inputDurationSkill) : null,
      };
      if (!(this.marking_point_input == null || this.marking_point_input === "")) data.marking_point = this.marking_point_input;
      await ApiService.post("prep-app/test/draft/skill/" + this.id_skill_test_mapping, data)
        .then(function (res) {
          if (res.status === 200) {
            vm.$store.commit("contentTestBuilderStore/updateSkill", {
              index: vm.indexSkill,
              duration: data.duration,
              marking_point: data.marking_point,
            });
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            if ([422].includes(error.response.status) ) {
              vm.messError(error.response.data.error.message);
            } else {
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
            }
          }
        });
      vm.dialogSkill = false;
    },
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
  },
};
</script>

<style></style>
